












































































































import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { State } from 'vuex-class';

import agentSelect from '../common/agentSelect.vue';

@Component({
    components: {
        agentSelect,
    },
})
export default class FormComponent extends Vue {
    @State((state) => state.common.user) user: any;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    rowData: any;

    @Prop({
        type: Boolean,
    })
    visable: boolean;

    roleList: any = [];

    form = {
        agentName: '',
        agentId: '',
        name: '',
        mobile: '',
        userEmail: '',
        password: '',
        roleId: '',
        status: 1,
    }

    rules = {
        agentId: [{
            required: true, type: 'number', message: this.$t('agentUserItem.pleaseEnteragentName'), trigger: 'blur',
        }],
        name: [{ required: true, message: this.$t('agentUserItem.pleaseEnterUserName'), trigger: 'blur' }],
        mobile: [
            {
                pattern: /^[+0-9]{6,20}$/, message: this.$t('agentCreate.pleaseEnterCorrectPhoneNumber'), required: true, trigger: 'change',
            },
        ],
        userEmail: [
            {
                required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: this.$t('agentCreate.pleaseEnterCorrectEmailFormat'), trigger: 'change',
            },
        ],
        password: [
            {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, required: true, message: this.$t('agentUserItem.pleaseEnterLoginPassword'), trigger: 'change',
            },
        ],
        // roleId: [{
        //     required: true, type: 'number', message: this.$t('agentUserItem.pleaseSelectRole'), trigger: 'blur',
        // }],
        status: [{
            required: true, type: 'number', message: this.$t('agentUserItem.pleaseEnterStatus'), trigger: 'blur',
        }],
    }

    get agentList() {
        if (this.user && this.user.agentId) {
            return [
                {
                    id: this.user.agentId,
                    name: this.user.agentName,
                },
            ];
        }
        return [];
    }

    @Watch('rowData', { immediate: true })
    onNavChange() {
        this.form = { ...this.form, ...this.rowData };
        if (!this.rowData.id) {
            // 新增
            this.form.agentId = this.user.agentId;
        } else {
            this.form.userEmail = this.rowData.email;
        }
    }

    @Watch('visable')
    onVisableChange() {
        if (this.visable === false) {
            this.emptyForm();
        }
    }

    @Watch('form.agentId', { immediate: true })
    onAgentChange() {
        if (this.form.agentId) {
            this.roleList = [];
            this.getRoleList();
        }
    }

    emptyForm() {
        (this.$refs.form as any).resetFields();
        this.form = {
            agentName: '',
            agentId: '',
            name: '',
            mobile: '',
            userEmail: '',
            password: '',
            roleId: '',
            status: 1,
        };
    }

    async getRoleList() {
        const { success, data } = await this.$request.get('/mapi/manager/role/list-role', {
            params: {
                agentId: this.form.agentId,
            },
        });
        if (success && data.data) {
            this.roleList = data.data;
        }
    }

    resetForm() {
        this.emptyForm();
        this.$emit('hidden', false);
    }

    async submitForm() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        (this.$refs.form as any).validate(async (valid: boolean) => {
            if (valid) {
                let url = '/mapi/manager/user/create-agent-emp';
                if (this.rowData.id) {
                    url = '/mapi/manager/user/update-agent-emp';
                }
                const { success } = await this.$request.post(url, {
                    // agentId: this.user.agentId,
                    ...this.form,
                    email: this.form.userEmail,
                });
                if (success) {
                    this.$mtd.message.success(this.$t('agentUserItem.saveSuccess') as any);
                    this.emptyForm();
                    this.$emit('hidden', true);
                }
            } else {
                this.$mtd.message.error(this.$t('agentUserItem.submitErrorTip') as any);
            }
        });
    }
}
